<style>
.add-owner {
    float: right;
}
</style>
<template>
    <div>
        <!-- Form Modal -->
        <b-modal
            id="modal-season-form"
            ref="myModal"
            centered
            no-close-on-backdrop
            no-close-on-esc
            size="lg"
            @ok="submit"
        >
            <!-- Custom Modal Header -->
            <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->
                <h5 v-if="market.id === null">Register Market Center</h5>
                <h5 v-if="market.id !== null">Edit Market Center</h5>

                <feather-icon class="ml-4 pr-0 cursor-pointer" icon="XIcon" size="16" @click="close()" />
            </template>

            <!-- Custom Modal Footer -->
            <template #modal-footer="{ ok, cancel }">
                <b-button size="sm" variant="primary" @click="ok()" :disabled="saving">
                    <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                    <span v-if="!saving">Save</span>
                    <span v-if="saving">Saving...</span>
                </b-button>
                <b-button size="sm" @click="cancel()" variant="outline-secondary">
                    Cancel
                </b-button>
            </template>

            <!-- Data Form in Modal Body -->
            <template #default="{ }">
                <validation-observer ref="dataForm" #default="{ invalid }">
                    <b-form ref="form" @submit.stop.prevent="handleCreate">
                        <b-row>
                            <b-col cols="12">
                                <location-filters
                                    size="sm"
                                    align="vertical"
                                    @filter="changeFilter"
                                />
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="2">
                                <b-form-group label-for="code" label="CODE">
                                    <validation-provider #default="{ errors }" name="code" rules="">
                                        <b-form-input
                                            id="code"
                                            name="code"
                                            v-model="market.code"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.code">{{ serverErrors.code[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="10">
                                <b-form-group label-for="name" label="Name">
                                    <validation-provider #default="{ errors }" name="name" rules="">
                                        <b-form-input
                                            id="name"
                                            name="name"
                                            v-model="market.name"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.name">{{ serverErrors.name[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            
                            <!-- <b-col cols="5">
                                <b-form-group labe-for="Transporter" label="Transporter">
                                    <b-form-select size="md" v-model="market.transporter_id">
                                        <b-form-select-option :value="null">-- Select --</b-form-select-option>
                                        <b-form-select-option v-for="transporter in transporters" :key="transporter.id"
                                            :value="transporter.id">
                                            {{ transporter.name.toUpperCase() }}
                                        </b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </b-col> -->
                        </b-row>

                        <b-row v-if="warehouses.length > 0">
                            <b-col cols="12" md="2">
                                <b-form-group label-for="warehouse_code" label="WH/CODE">
                                    <validation-provider #default="{ errors }" name="warehouse_code" rules="">
                                        <b-form-select
                                            id="warehouse_code"
                                            name="warehouse_code"
                                            size="sm"
                                            v-model="market.storage_id"
                                        >
                                            <b-form-select-option :value="null">Select---</b-form-select-option>
                                            <b-form-select-option v-for="warehouse in warehouses" :key="warehouse.id" :value="warehouse.id">
                                                {{ warehouse.code }}
                                            </b-form-select-option>
                                        </b-form-select>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="10">
                                <b-form-group label-for="warehouse_name" label="Local Warehouse Storage">
                                    <validation-provider #default="{ errors }" name="warehouse_name" rules="">
                                        <b-form-select
                                            id="warehouse_name"
                                            name="warehouse_name"
                                            size="sm"
                                            v-model="market.storage_id"
                                        >
                                            <b-form-select-option :value="null">Select---</b-form-select-option>
                                            <b-form-select-option v-for="warehouse in warehouses" :key="warehouse.id" :value="warehouse.id">
                                                {{ warehouse.name.toUpperCase() }}
                                            </b-form-select-option>
                                        </b-form-select>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <!-- Conveyor lins list -->
                        <b-row>
                            <b-col cols="12" md="12">
                                <b-row>
                                    <b-col cols="12" md="12" class="d-flex justify-content-end">
                                        <b-button size="sm" class="add-owner mb-1" variant="outline-primary" @click="addBuyingLine()">
                                            Add Buying Line
                                        </b-button>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12" md="12">
                                        <b-table
                                            striped
                                            hover
                                            small
                                            class="position-relative"
                                            :items="market.lines"
                                            responsive
                                            :fields="linesColumns"
                                            primary-key="id"
                                            :sort-by.sync="sortBy"
                                            show-empty empty-text="No records found"
                                            :sort-desc.sync="isSortDirDesc"
                                            sticky-header
                                        >
                                            <template #cell(code)="data">
                                                <validation-provider #default="{ errors }" name="line_code" rules="">
                                                    <b-form-input
                                                        :id="'line_code'+data.index"
                                                        :name="'line_code'+data.index"
                                                        v-model="market.lines[data.index].code"
                                                        :state="errors.length > 0 ? false : null"
                                                        size="sm"
                                                    />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                    <small class="text-danger" v-if="serverErrors && serverErrors.line_code">{{ serverErrors.line_code[0] }}</small>
                                                </validation-provider>
                                            </template>

                                            <template #cell(name)="data">
                                                <validation-provider #default="{ errors }" name="line_name" rules="">
                                                    <b-form-input
                                                        :id="'line_name'+data.index"
                                                        :name="'line_name'+data.index"
                                                        v-model="market.lines[data.index].name"
                                                        :state="errors.length > 0 ? false : null"
                                                        size="sm"
                                                    />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                    <small class="text-danger" v-if="serverErrors && serverErrors.line_name">{{ serverErrors.line_name[0] }}</small>
                                                </validation-provider>
                                            </template>
                                            <template #cell(actions)="data">
                                                <b-button
                                                    variant="outline-danger"
                                                    size="sm"
                                                    @click="removeLine(data.index)"
                                                    title="Delete this line"
                                                >
                                                    <feather-icon icon="TrashIcon" size="12" class="text-danger stroke-current" />
                                                </b-button>
                                            </template>
                                        </b-table>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>

                        <hr />
                        <b-row>
                            <b-col>
                                <b-button size="sm" class="add-owner mb-1" variant="outline-primary" @click="addElement()">Add Owner</b-button>

                            </b-col>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th>Owner</th>
                                        <th width="20%">Share </th>
                                        <th width="5%"> </th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr v-for="element, index in market.owners" :key="index">
                                        <td scope="row">
                                            <div>
                                                <b-form-select size="sm" v-model="element.type" :options="options"></b-form-select>
                                            </div>
                                        </td>
                                        <td valign="center">
                                            <p v-if="element.type == 1">{{ clientName.name }}</p>

                                            <b-form-select size="sm" v-model="element.owner" v-if="element.type == 2">
                                                <b-form-select-option :value="null">-- Select --</b-form-select-option>
                                                <b-form-select-option v-for="society in societies" :key="society.id" :value="society.id">
                                                    {{ society.name.toUpperCase() }} {{ society.code }}
                                                </b-form-select-option>
                                            </b-form-select>

                                            <b-form-select size="sm" v-model="element.owner" v-if="element.type == 3">
                                                <b-form-select-option :value="null">-- Select --</b-form-select-option>
                                                <b-form-select-option v-for="company in companies" :key="company.id" :value="company.id">
                                                    {{ company.name.toUpperCase() }}
                                                </b-form-select-option>
                                            </b-form-select>
                                        </td>
                                        <td>
                                            <div>
                                                <b-form-input id="share" name="share" size="sm" v-if="element.type == 1"
                                                    v-model="sharePerc.count" disabled width="80%" />
                                                <b-form-input id="share" name="share" v-model="element.share" size="md"
                                                    v-if="element.type > 1" @input="handlePerc(element.share)" />

                                            </div>

                                        </td>
                                        <td>
                                            <b-button variant="outline-danger" size="sm"
                                                @click="removeRow(index, element.share)">
                                                <feather-icon icon="TrashIcon" size="11"
                                                    class="text-danger stroke-current" />
                                            </b-button>
                                        </td>
                                        <!-- {{ element }} -->
                                    </tr>
                                    <tr>
                                        <td scope="row"></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </b-row>

                        <!-- <pre>
                            {{ market }}
                        </pre> -->
                    </b-form>
                </validation-observer>
            </template>
        </b-modal>
        <!-- ./Form Modal -->

        <!-- Filters -->
        <b-card no-body>
            <b-card-header class="pb-50">
                <h5>Filter Location</h5>
            </b-card-header>
            <b-card-body>
                <b-row>
                    <b-col cols="12" md="8" class="mb-md-0 mb-2">
                        <location-filters
                            size="sm"
                            align="horizontal"
                            @filter="changeFilter"
                        />
                    </b-col>
                    <b-col cols="12" md="4" class="pt-2" v-if="client.type == 1">
                        <b-button
                            variant="flat-success"
                            class="btn-icon float-right"
                            size="sm"
                            title="Download Excel"
                            @click="downloadList(true)"
                        >
                            <img src="@/assets/images/icons/export_xls.png" width="24" height="24" />
                        </b-button>

                        <b-button
                            variant="flat-danger"
                            class="btn-icon mr-1 float-right"
                            size="sm"
                            title="Download pdf"
                            @click="downloadList(false)"
                        >
                            <img src="@/assets/images/icons/pdf_export.png" width="24" height="24" />
                        </b-button>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
        <!-- ./Filters -->

        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions" :clearable="false"
                            class="per-page-selector d-inline-block mx-50 select-size-sm" />
                        <label>entries</label>
                    </b-col>
                    <!-- ./Per Page -->

                    <!-- Search & Button -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input
                                v-model="searchQuery"
                                class="d-inline-block mr-1"
                                placeholder="Search..."
                                size="sm"
                            />
                            <b-button variant="primary" size="sm" @click="invokeCreateForm()">
                                <span class="text-nowrap">Register Market</span>
                            </b-button>
                        </div>
                    </b-col>
                    <!-- ./Search & Button-->
                </b-row>
                <!-- ./Table Top -->
            </div>

            <!-- Table -->
            <b-table
                striped
                hover
                small
                ref="records"
                class="position-relative"
                :items="fetch"
                responsive
                :fields="columns"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty empty-text="No records found"
                :sort-desc.sync="isSortDirDesc"
            >
                <template #cell(code)="data">
                    {{ data.item.code ? data.item.code.toUpperCase() : '' }}
                </template>

                <template #cell(name)="data">
                    {{ data.item.name.toUpperCase() }}
                </template>

                <template #cell(address)="data">
                    {{ data.item.address ? data.item.address.location.name.toUpperCase() : '' }}
                </template>

                <template #cell(storage)="data">
                    <span v-if="data.item.storage && data.item.storage.code">{{ data.item.storage.code.toUpperCase() }}: {{ data.item.storage.name.toUpperCase() }}</span>
                    <span v-else-if="data.item.storage">{{ data.item.storage.name.toUpperCase() }}</span>
                    <span v-else></span>
                </template>

                <template #cell(transporter)="data">
                    <span v-if="data.item.transporter && data.item.transporter.code">{{ data.item.transporter.code.toUpperCase() }}: {{ data.item.transporter.name.toUpperCase() }}</span>
                    <span v-else-if="data.item.transporter">{{ data.item.transporter.name.toUpperCase() }}</span>
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">

                        <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                        </template>

                        <b-dropdown-item @click="invokeUpdateForm(data.item)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="confirmDelete(data.item.id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>

            <!-- Table Footer -->
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage" first-number
                            last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
    BForm, BFormGroup,
    BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem,
    BSpinner,
} from 'bootstrap-vue'
import LocationFilters from '@core/components/cromis/filters/LocationFilters.vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import store from '@/store'
import axios from '@axios'
import { ref, onUnmounted, onMounted, reactive } from '@vue/composition-api'
import marketsStoreModule from '@/views/cromis/markets/marketsStoreModule'
import useMarketsList from '@/views/cromis/markets/useMarketsList'

export default {
    props: {},
    components: {
        BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
        BForm, BFormGroup,
        BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem,
        BSpinner,
        ValidationObserver, ValidationProvider,

        LocationFilters,
    },
    directives: {},
    watch: {
        userInput(newInput, oldInput) {
            // Update default value when user input changes
            if (!isNaN(newInput)) {
                this.defaultValue -= (newInput - oldInput);
            } else {
                // Handle case where userInput is NaN (e.g., after deleting the number)
                this.defaultValue = this.defaultValue;
            }
        },
    },

    setup(props, context) {
        const dataForm = ref(null)
        const myModal = ref(null)
        const saving = ref(false)
        const serverErrors = ref(null)

        const client = ref(JSON.parse(localStorage.getItem('userData')).client)

        const selections = ref([])
        const formSelections = ref([])
        const levels = ref(null)
        const companies = ref([])
        const societies = ref([])
        const warehouses = ref([])
        const children = ref([])
        const transporters = ref([])

        const linesColumns = [
            { key: 'code', label: 'Code', sortable: false, thStyle: { width: "20%" }, thClass: 'text-left', tdClass: 'text-left' },
            { key: 'name', label: 'Name', sortable: true },
            { key: 'actions', label: '', sortable: false, thStyle: { width: "10%" }, thClass: 'text-center', tdClass: 'text-center' },
        ]

        const market = ref({
            id: null,
            location_id: null,
            code: null,
            name: null,
            storage_id: null,
            transporter_id: null,
            lines: [],
            owners: [
                {
                    owner_id: null,
                    type: null,
                    share: null,
                }
            ]
        })

        const formField = reactive([
            { owner: null, type: null, share: null }
        ]);


        let sharePerc = reactive({ count: 100 })

        const addElement = () => {
            formField.push({ owner: '', type: '', share: '' })
            market.value.owners.push({ owner: '', type: '', share: '' })

            if (market.value.owners.length > 0) {

                market.value.owners.forEach(el => {
                    if (el.type == 1) {
                        el.owner = JSON.parse(localStorage.getItem('userData')).client.id
                        el.share = sharePerc.count
                    }
                });
            }
        }

        const addBuyingLine = () => {
            market.value.lines.push({
                id: null,
                code: null,
                name: null,
            })
        }

        const removeRow = (index, share) => {
            if ( market.value.owners.length > 1) {
                sharePerc.count = parseInt(sharePerc.count) + parseInt(share)
                market.value.owners.splice(index, 1)
            }
        }

        const removeLine = (index, share) => {
            if ( market.value.lines.length > 0) {
                market.value.lines.splice(index, 1)
            }
        }

        const handlePerc = (data) => {
            // Handle input change

            if (sharePerc.count == NaN || sharePerc.count == null) {
                sharePerc.count = 0;
                formField.forEach(el => {
                    sharePerc.count += parseInt(el.share)
                });

                sharePerc.count = 100 - sharePerc.count
            }

            sharePerc.count = parseInt(sharePerc.count) - parseInt(data)
        }

        const CROMIS_STORE_MODULE_NAME = 'cromis-market'
        // Register module
        if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, marketsStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
        })

        onMounted(async () => {
            await store.dispatch('cromis-market/transporters')
                .then(response => {
                    transporters.value = response.data.transporters
                })
                .catch(error => {
                    console.log('got error', error)
                })

            await store.dispatch('cromis-market/companies')
                .then(response => {
                    companies.value = response.data.companies
                })
                .catch(error => {
                    console.log('got error', error)
                })

            await store.dispatch('cromis-market/societies')
                .then(response => {
                    societies.value = response.data.societies
                })
                .catch(error => {
                    console.log('got error', error)
                })

            await store.dispatch('cromis-market/warehouses', {location_id: locationFilter.value })
                .then(response => {
                    warehouses.value = response.data.warehouses
                })
                .catch(error => {
                    console.log('got error', error)
                })
        })

        const changeFilter = async (filteredValue) => {
            market.value.location_id = filteredValue != null? filteredValue.id : null
            locationFilter.value = filteredValue != null? filteredValue.id : null

            await store.dispatch('cromis-market/warehouses', {location_id: locationFilter.value })
                .then(response => {
                    warehouses.value = response.data.warehouses
                    if(warehouses.value.length == 0){
                        market.value.warehouse_id = null
                    }
                })
                .catch(error => {
                    console.log('got error', error)
                })
        }

        const downloadList = async (isExcel) => {
            let type = isExcel? 1 : 0
            window.open(`${ axios.defaults.baseURL }/reports/markets_list?view=${ client.value.id }&type=${ type }&location_id=${ locationFilter.value }`, '_blank')
        }

        const {
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,

            // Filers
            locationFilter,
        } = useMarketsList()

        // Form Methods
        const invokeUpdateForm = (item) => {
            serverErrors.value = null

            if(warehouses.value.length == 0 && item.storage != null){
                warehouses.value.push(item.storage)
            }

            market.value = {
                id: item.id,
                location_id: item.address ? item.address.location.id : null,
                code: item.code,
                name: item.name,
                storage_id: item.storage != null? item.storage.id : null,
                transporter_id:item.transporter ? item.transporter.id: null,
                lines: item.lines.map((e) => {
                    return {
                        id: e.id,
                        code: e.code,
                        name: e.name,
                    }
                }),
                owners: item.owners.map((e) => {
                    return { owner_id: e.owner.id, type: e.type, share: e.share };
                }),
            }

            locationFilter.value = item.address != null? item.address.id : null

            myModal.value.show()
        }

        const invokeCreateForm = () => {
            market.value = {
                id: null,
                location_id: null,
                code: null,
                name: null,
                storage_id: null,
                transporter_id:null,
                lines: [],
                owners: [
                    {
                        owner_id: JSON.parse(localStorage.getItem('userData')).client.id,
                        type: 1,
                        share: 100,
                    }
                ]
            }

            myModal.value.show()
        }

        const isFormValid = async () => {
            let isValid = false
            await dataForm.value.validate().then(success => {
                isValid = success
            })

            return isValid
        }

        const submit = (bvModalEvt) => {
            bvModalEvt.preventDefault()

            serverErrors.value = null
            // Handle form submit
            if (market.value.id === null || market.value.id === 0)
                handleCreate()
            else
                handleUpdate(market.value)
        }




        const handleCreate = async () => {
            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }


            await store.dispatch('cromis-market/create', market.value)
                .then(response => {
                    refetch()
                    saving.value = false

                    myModal.value.hide()

                    context.root.$swal({
                        icon: 'success',
                        text: `Market has been added successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }

        const handleUpdate = async (item) => {
            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }

            await store.dispatch('cromis-market/update', { id: item.id, data: item })
                .then(response => {
                    refetch()
                    saving.value = false

                    myModal.value.hide()

                    context.root.$swal({
                        icon: 'success',
                        text: `Changes to market ${response.data.code} ${response.data.name} has been saved successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }

        const confirmDelete = async (id) => {
            const result = context.root.$swal({
                icon: 'info',
                title: 'System Info',
                text: 'Are you sure you want to delete the Market?',
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel',
                customClass: {
                    confirmButton: 'btn btn-info',
                    cancelButton: 'btn btn-danger ml-2', // Optional: Customize the cancel button class
                },
                buttonsStyling: true,
            }).then((result) => {

                if (result.value) {
                    remove(id)
                } else {
                    context.root.$swal({
                        icon: 'info',
                        title: 'Cancelled',
                        text: 'Your market is safe you cancell deletion of it',
                        showConfirmButton: true,
                        customClass: {
                            confirmButton: 'btn btn-info',
                        },
                        buttonsStyling: true,
                    })
                }
            })
        }

        const remove = async (id) => {
            await store.dispatch('cromis-market/remove', id)
                .then(response => {
                    refetch()
                })
                .catch(error => {
                    context.root.$swal({
                        icon: 'error',
                        title: 'Server Error',
                        text: 'Something went wrong. See tech support',
                        showConfirmButton: true,
                        customClass: {
                            confirmButton: 'btn btn-danger',
                        },
                        buttonsStyling: true,
                    })
                })
        }

        return {
            // Data
            selections,
            formSelections,
            levels,
            children,
            market,
            sharePerc,
            selectedOwner: null,
            addedElement: null,
            client,
            clientName: JSON.parse(localStorage.getItem('userData')).client,
            options: [
                { value: null, text: 'Select Owner' },
                { value: '1', text: 'Company' },
                { value: '2', text: 'SubZones' },
                { value: '3', text: 'Third Part' }
            ],

            fetch,
            columns,
            linesColumns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,

            // Methods
            required,
            saving,
            serverErrors,
            dataForm,
            myModal,
            changeFilter,
            downloadList,
            invokeCreateForm,
            invokeUpdateForm,
            submit,
            isFormValid,
            handleCreate,
            handleUpdate,
            remove,
            confirmDelete,
            addElement,
            addBuyingLine,
            formField,
            removeRow,
            removeLine,
            handlePerc,
            companies,
            societies,
            warehouses,
            transporters,
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>